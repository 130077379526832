<template>
  <div class="typed-doc-picker mt-n4">
    <document-ref-list
      :items.sync="computedSelectedIds"
      :linkTarget="linkTarget"
      v-on="$listeners"
      :readonly="readonlyList"
      :chip="chip"
      :dense="dense"
    />
    <div
      class="wrapper"
      ref="wrapper"
    >
      <v-autocomplete
        v-if="!readonly"
        hide-no-data
        hide-details
        return-object
        no-filter
        :attach="typeof attach !== 'undefined' ? attach : $refs.wrapper"
        :dense="dense"
        :solo="solo"
        :flat="flat"
        :label="label || $t('t.Search')"
        @update:search-input="setSearchText($event)"
        :clearable="clearable"
        :items="suggestions"
        :rules="rules"
        :disabled="disabled"
        :readonly="readonly"
        :loading="computedLoading"
        :rounded="rounded"
        :placeholder="placeholder"
        :menu-props="computedMenuProps"
        v-model="value"
        v-click-outside="clickOutside"
        @change="propagateChange"
        @keydown.esc="closeMenu"
        @click="handleClick"
        item-color=""
        ref="autocomplete"
      >
        <template v-slot:selection>
        </template>
        <template v-slot:append>
          <slot name="append" />
        </template>

        <template v-slot:item="data">
          <v-list-item-content>
            <document-picker-list-item-ref
              :item="data.item"
              :show-icon="true"
              class="clickable"
              @change="propagateChange"
              :user-ref-props="{ isForSelection: true}"
            />
          </v-list-item-content>
        </template>
        <template
          v-if="search.resultCount > search.items.length"
          v-slot:append-item
        >
          <v-list-item
            @mousedown="search.retrieveNextPage()"
            class="clickable more-items"
          >
            <v-list-item-content>
              <document-picker-list-item-fallback :item="{name: $tc('t.MoreItems', search.resultCount - search.items.length), type: 'labels'}" />
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- <template
        v-slot:append
        @click="handleClick"
      >
        <slot name="append" />
      </template> -->
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import Search from '@/pages/search/controllers'

export default {
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    DocumentPickerListItemFallback: () => import('@/components/document-picker-list-item-fallback'),
    DocumentRefList: () => import('@/components/document-ref-list')
  },
  data: function () {
    return {
      currentPage: 0,
      http: this.$http().debounced(),
      innerLoading: false,
      isOutOfView: false,
      menuIsOpen: false,
      observer: new ResizeObserver(this.eventHandler),
      innerSelectedDocument: null,
      innerSelectedIds: [],
      innerValue: [],
      listItemHeight: 1,
      updatingSelectedValues: false,
      search: new Search()
        .setFilters(this.filters)
        .chain(s => s.searchedDocumentTypes.include(Array.isArray(this.documentTypes) ? this.documentTypes : [this.documentTypes]))
    }
  },
  computed: {
    computedMenuProps: {
      get () {
        const top = typeof this.top === 'boolean' ? this.top : this.isOutOfView
        return {
          closeOnContentClick: false,
          contentClass: 'background-plain',
          eager: this.attach !== false,
          maxHeight: 5 * this.listItemHeight + 16,
          nudgeBottom: top ? 0 : 8,
          offsetY: true,
          top,
          value: this.menuIsOpen
        }
      }
    },
    clickOutside () {
      return {
        handler: this.closeMenu,
        include: () => [...(this.$el?.querySelectorAll('.more-items,.v-btn') ?? [])]
      }
    },
    computedLoading () {
      return this.search.loading || (this.observer.status === 1)
    },
    computedSelectedIds: {
      get () {
        return this.innerSelectedIds || []
      },
      set (val) {
        val = val?.filter(v => v && v.id && v.type) || []

        if (!this.multiple && val.length > 1) {
          val = [val[0]]
        }

        if (!this.lodash.isEqual(this.innerSelectedIds, val)) {
          this.innerSelectedIds = val
          if (!this.updatingSelectedValues) {
            this.$emit('update:selected', val)
          }
        }
        this.updatingSelectedValues = false
      }
    },
    computedExcludedItems () {
      return this.computedSelectedIds.concat(this.excludedItems)
    },
    suggestions () {
      return this.search.items
    },
    value: {
      get () {
        return this.innerValue
      },
      set (val) {
        if (val && val.id && val.type) {
          this.computedSelectedIds = this.multiple ? [...this.computedSelectedIds, val] : [val]
        }

        this.innerValue = val

        this.$nextTick(() => {
          this.innerValue = []
        })
      }
    }
  },
  methods: {
    closeMenu () {
      this.menuIsOpen = false
    },
    openMenu () {
      this.$nextTick(() => {
        this.menuIsOpen = true
        this.$refs.autocomplete?.activateMenu()
      })
    },
    setSearchText (v) {
      if (v) {
        this.search.searchText = v
      }
    },
    handleClick () {
      if (this.readonly) {
        return
      }
      this.openMenu()
      this.search.clearText().execute()
      this.$nextTick(() => {
        this.computeListItemHeight()
      })
    },
    async computeListItemHeight () {
      this.listItemHeight = await this.$waitFor(() => document.querySelector(`#${this.$refs.autocomplete.computedOwns} .v-list-item:first-child`).clientHeight)
    },
    eventHandler (entries) {
      const rect = entries[entries.length - 1].contentRect
      this.isOutOfView = (document.body.getBoundingClientRect().bottom - document.querySelector('footer').offsetHeight) < (this.$refs.wrapper.getBoundingClientRect().bottom + rect.bottom)
      this.$triggerResize()
    },
    propagateChange (e) {
      this.$emit('change', e)
    }
  },
  async mounted () {
    const e = await this.$waitFor(() => this.$el.querySelector('div.v-menu__content'))
    if (e) {
      this.observer.observe(e)
    }
  },
  beforeDestroy () {
    this.search = undefined
    this.observer.disconnect()
  },
  watch: {
    documentTypes (n, o) {
      if (!this.lodash.isEqual(n, o)) {
        this.search.chain(s => s.searchedDocumentTypes.clear().include(Array.isArray(n) ? n : [n])).execute()
      }
    },
    computedLoading (n) {
      this.$emit('update:loading', n)
    },
    computedExcludedItems (excludedItems) {
      this.search.searchedIds.clear().exclude(excludedItems.map(i => i.id))
      if (this.menuIsOpen) {
        this.search.execute()
      }
    },
    multiple (n) {
      if (!n && this.computedSelectedIds.length > 1) {
        this.computedSelectedIds = [this.computedSelectedIds[0]]
      }
    },
    selected: {
      handler (n) {
        this.updatingSelectedValues = true
        this.computedSelectedIds = Array.isArray(n) ? n : [n]
      },
      immediate: true
    }
  },
  props: {
    flat: Boolean,
    solo: Boolean,
    label: String,
    documentTypes: {
      required: true,
      type: [Array, String]
    },
    excludedItems: {
      type: Array,
      default: () => [],
      required: false
    },
    filters: {
      type: Object,
      required: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    multiple: Boolean,
    rules: Array,
    selected: [Array, Object],
    disabled: Boolean,
    readonly: Boolean,
    loading: Boolean,
    placeholder: {
      type: String,
      default: ''// `${this.$t('t.Search')}...`
    },
    result: Object,
    rounded: Boolean,
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    top: {
      type: Boolean,
      default: undefined
    },
    navigate: Boolean,
    linkTarget: Object,
    chip: Boolean,
    readonlyList: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  position relative

.typed-doc-picker
  display flex
  flex-direction column
</style>
